/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em",
    h1: "h1",
    pre: "pre",
    code: "code",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.em, null, "Jeg skal bli den beste Angularutvikleren jeg kan bli. For å bli best må man kunne det grunnleggende godt. Meget godt!")), "\n", React.createElement(_components.h1, null, "🤔 Hva er greia med ng-template?"), "\n", React.createElement(_components.p, null, "I korte trekk: ng-template er direktivet som produseres i bakkant når du bruker snacksy angularsukker som *ngIf og *ngFor."), "\n", React.createElement(_components.p, null, "Du skriver dette:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "<div *ngIf=\"noe som kan være sant\">\n    Et eller annet..\n</div>\n")), "\n", React.createElement(_components.p, null, "og Angular produserer no a la dette i bakkant:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "<ng-template [ngIf]=\"noe som kan være sant\">\n  <div>\n    Et eller annet..\n  </div>\n</ng-template>\n")), "\n", React.createElement(_components.p, null, "Ikke så veldig livsendrende informasjon å sitte på, men kult å vite. Uansett så har du nok vært borti iværtfall to anledning hvor du har vært nødt til å benytte ng-template."), "\n", React.createElement(_components.h3, null, "🔀 En else til din ngIf"), "\n", React.createElement(_components.p, null, "Du kan selvfølgelig fyre opp en *ngIf for både den positive og den negative tilstanden, sånn ca. slik:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "<div *ngIf=\"noe\">\n    Et eller annet..\n</div>\n\n<div *ngIf=\"!noe\">\n    Et eller annet annet...\n</div>\n")), "\n", React.createElement(_components.p, null, "For så vidt greit. Innhold inne i ", React.createElement("ng-template"), " vil ikke i utgangspunktet vise seg, det må ha ett eller annet som driver det frem. Men hvis du bruker dem med en template reference variable kan du referere til elemente i andre sammenhenger og få frem innholdet."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "<div *ngIf=\"noe else alternativet\">\n    Et eller annet..\n</div>\n\n<ng-template #alternativet\">\n    Et eller annet annet...\n</ng-template>\n")), "\n", React.createElement(_components.p, null, "eller evt."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "<ng-template [ngIf=\"noe\"] [ngIfElse]=\"alternativet\">\n    Et eller annet..\n</ng-template>\n\n<ng-template #alternativet\">\n    Et eller annet annet...\n</ng-template>\n")), "\n", React.createElement(_components.p, null, "for *ngFor blir magien i bakkant slik:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "  // det som skal itereres over\n  const persons = [\n    {name: 'geir'},\n    {name: 'tore'},\n    {name: 'berit'},\n  ]\n\n<ul>\n  <li *ngFor=\"let person of persons; let i = index;\">{{ i }}: {{ person.name }}</li>\n</ul>\n\n// Blir det samme som:\n\n<ul>\n  <ng-template ngFor let-person let-i=\"index\" [ngForOf]=\"persons\">\n    <li>{{ i }}: {{ person.name }}</li>\n  </ng-template>\n</ul>\n")), "\n", React.createElement("br"), "\n", React.createElement(_components.h3, null, "🛑 Flere strukturelle direktiver på samme element? Njet!"), "\n", React.createElement(_components.p, null, "Det hender jo absolutt innimellom at man ønsker å løkke gjennom noe 'hvis' ett eller annet. Da hadde det jo vært glimrende å kunne gjøre noe slikt:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "  <div *ngFor=\"let cat of cats\" *ngIf=\"noDogs\">\n    {{ cat.hungry? }}\n  </div>\n")), "\n", React.createElement(_components.p, null, "Akk, men nei! Det lar seg ei gjøre. Løsning kan jo tenkes å bli noe slik da:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "  <div  *ngIf=\"noDogs\">\n    <div *ngFor=\"let cat of cats\">\n      {{ cat.hungry? }}\n    </div>\n  </div>\n")), "\n", React.createElement(_components.p, null, "Litt skittent med litt ekstra fjas i dokumentet 🚯, så hva hvis vi henter inn en venn av ng-template? La meg introdusere ng-content!"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "  <ng-content  *ngIf=\"noDogs\">\n    <div *ngFor=\"let cat of cats\">\n      {{ cat.hungry? }}\n    </div>\n  </ng-content>\n")), "\n", React.createElement(_components.p, null, "🏆 Kult! Fungerer som bare det. ng-content har så meget kult å by på. Jeg tenker at vi gløtter litt mer på den i en egen post."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
